import { useContext, useEffect } from 'react';
import { hasRole, LayoutContext } from 'wcz-layout';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { NewApplicationProvider } from '../components/new_application/NewApplicationProvider';
import { UserRoles } from '../utils/UserRoles';
import AccessDenied from './AccessDenied';
import CreateLocation from '../components/new_application/CreateLocation';

export default function CreateLocationPage() {
    const upperTheme = useTheme();
    const distinctPaper = createTheme({
        components: {
            MuiPaper: {
                defaultProps: {
                    elevation: 2
                },
                styleOverrides: {
                    root: ({ ownerState }) => {
                        if (ownerState.className?.split(" ").includes("section-paper"))
                            return {
                                backgroundColor: "inherit",
                                margin: "1.5em 1em",
                                padding: "1em"
                            };
                    }
                }
            }
        }
    }, upperTheme);

    const { changeTitle, t } = useContext(LayoutContext);

    useEffect(() => {
        changeTitle(t("NewLocation"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!hasRole([UserRoles.CSR])) {
        return <AccessDenied />;
    }

    return (
        <ThemeProvider theme={distinctPaper}>
            <NewApplicationProvider>
                <CreateLocation />
            </NewApplicationProvider>
        </ThemeProvider>
    );
}