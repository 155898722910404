import { useState, useContext, useEffect } from 'react';
import { Button, FormControlLabel, TextField } from '@mui/material';
import InfoTooltip from '../components/common/InfoTooltip';
import { adminUrls } from '../utils/BaseUrl';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchGet, fetchPut, hasRole, LayoutContext } from 'wcz-layout';
import { UserRoles } from '../utils/UserRoles';
import AccessDenied from './AccessDenied';

export default function CasAdmin() {
    const { changeTitle, t, snackbar } = useContext(LayoutContext);
    const [casSvhcNumbers, setCasSvhcNumbers] = useState<string>("");

    useEffect(() => {
        changeTitle(t("Administration"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useQuery<string[]>(["svhcCasNumbers"], ({ signal }) => fetchGet(adminUrls.svhcCas, signal), {
        onSuccess: (data: string[]) => {
            setCasSvhcNumbers(data.join("; "));
        }
    });

    const updateSvhcCas = useMutation((casNumbersString: string) => fetchPut(adminUrls.svhcCas, casNumbersString), {
        onSuccess: () => snackbar({ message: t("SvhcCasNumbersSaved"), severity: 'success' })
    });

    const handleUpdate = () => {
        updateSvhcCas.mutateAsync(casSvhcNumbers);
    };

    if (!hasRole([UserRoles.CSR])) {
        return <AccessDenied />;
    }

    return (<div style={{ display: "flex", flexDirection: "column", marginTop: "2rem" }}>
        <FormControlLabel
            label={<>
                {t("AllSvhcDangerousCasNumbers")}
                <InfoTooltip text={"List of all CAS numbers of substances of high concern. Separate CAS numbers with semicolon( ; ) symbol. Ctrl + F to search on the page."} />
            </>}
            labelPlacement="top"
            control={<TextField
                value={casSvhcNumbers}
                onChange={(e) => setCasSvhcNumbers(e.target.value)}
                multiline
                minRows={30}
                maxRows={30}
                style={{ minWidth: "80vw" }}
            />} />
        <Button onClick={handleUpdate}>
            {t("SaveSvhcDangerousCasNumbers")}
        </Button>
    </div>);
}