import { useContext, useState } from 'react';
import { Autocomplete, Button, Container, Paper, TextField, Typography } from '@mui/material';
import { NewApplicationContext } from '../../utils/NewApplicationContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import PeopleModel from '../../models/PeopleModel';
import { LayoutContext, fetchGet, fetchPost, newGuid } from 'wcz-layout';
import { locationUrls, peoplesoftUrl } from '../../utils/BaseUrl';
import { uniqBy } from 'lodash';
import LocationDto from '../../dtos/LocationDto';

const locationInit = {
    id: newGuid(),
    name: "",
    pic: {
        id: "",
        name: ""
    }
};

export default function LocationSection() {
    const newApplicationContext = useContext(NewApplicationContext);
    const { user, t, snackbar } = useContext(LayoutContext);
    const [peopleList, setPeopleList] = useState([] as { label: string, id: string }[]);
    const [newLocation, setNewLocation] = useState<LocationDto>(locationInit);
    const locationStates = newApplicationContext.location;
    const locationState = locationStates.modelState;
    const isEdit = false;

    useQuery<PeopleModel[]>(["people"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=status==Active&fields=employeeId,nameTitleCase,departmentId`, signal), {
        enabled: !isEdit,
        onSuccess: (data: PeopleModel[]) => {
            const uniqueUsers = uniqBy(data, 'nameTitleCase');
            setPeopleList(uniqueUsers.map((person) => {
                return {
                    id: person.employeeId,
                    label: `${person.nameTitleCase} (${person.departmentId})`
                };
            }));
            if (!isEdit) {
                locationState[1](previous => ({
                    ...previous,
                    pic: {
                        label: `${user.name} (${user.department})`,
                        id: user.id
                    }
                }));
            }
        }
    });

    const { mutate } = useMutation((data: LocationDto) => fetchPost(locationUrls.create, data),
        {
            onSuccess: () => {
                snackbar({ message: t("LocationCreated"), severity: 'success' });
                setNewLocation(locationInit);
            }
        });

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewLocation({ ...newLocation, name: e.target.value });
    };

    const handleAutocompleteChange = (event: React.SyntheticEvent<Element, Event>, value: { label: string, id: string } | null) => {
        if (value) {
            setNewLocation({
                ...newLocation,
                pic: {
                    id: value.id,
                    name: value.label
                }
            });
        } else {
            setNewLocation({
                ...newLocation,
                pic: {
                    id: "",
                    name: ""
                }
            });
        }
    };

    const handleCreateLocation = () => mutate(newLocation);

    return (
        <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Paper sx={{ width: 500 }} className="section-paper">
                <Typography sx={{ m: 2, fontWeight: "bold", fontSize: 20 }}>{t("Location")}</Typography>

                <TextField sx={{ mb: 2 }} label={t("Name")} variant="standard" value={newLocation.name} onChange={handleNameChange} fullWidth />

                <Autocomplete
                    options={peopleList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} variant="standard" label="PIC" />}
                    onChange={handleAutocompleteChange}
                    fullWidth
                />
            </Paper>

            <Button variant="contained" disabled={!newLocation.name || !newLocation.pic.id} onClick={handleCreateLocation}>{t("NewLocation")}</Button>
        </Container>
    );
}