import { Button, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useContext } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchDelete, fetchGet, hasRole, LayoutContext } from 'wcz-layout';
import Loading from '../components/common/Loading';
import LocationDetailDto from '../dtos/LocationDetailDto';
import { chemicalLocationRelationUrls, locationUrls } from '../utils/BaseUrl';
import { formatDateTime } from '../utils/StringUtils';
import { UserRoles } from '../utils/UserRoles';
import { Delete, QrCode2Sharp } from '@mui/icons-material';
import LocalStorageHandler from '../utils/LocalStorageHandler';
import AccessDenied from './AccessDenied';

type LocationDetailParams = {
    id: string
}

export default function LocationDetailPage() {
    const { t, changeTitle, snackbar } = useContext(LayoutContext);
    const navigate = useNavigate();
    useEffect(() => {
        changeTitle(t("Location"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    const { id } = useParams<LocationDetailParams>();
    const { data } = useQuery<LocationDetailDto>(["locationDetail"], ({ signal }) => fetchGet(locationUrls.detail + id, signal));

    const { mutate: deleteRelation } = useMutation((id: string) =>
        fetchDelete(chemicalLocationRelationUrls.delete + id),
        {
            onSuccess: () => {
                snackbar({ message: `${t("Chemical")} ${t("deleted")} ${t("fromLocation")}`, severity: 'success' });
            }
        }
    );

    const link = (chemicalId: string) => () => {
        navigate(`/chemical/${chemicalId}`);
    };

    if (!data)
        return <Loading />;

    if (!hasRole([UserRoles.CSR, UserRoles.BUYER, UserRoles.RECEPTION, UserRoles.WAREHOUSE])) {
        return <AccessDenied />;
    }

    return (
        <Paper sx={{ margin: { xs: "0em auto", md: "1em auto" }, padding: "1em", maxWidth: "800px" }}>
            <h3 style={{ fontWeight: "bold" }}>{t("Name")}: {data.name}</h3>
            <div>
                <span style={{ fontWeight: "bold" }}>{t("Pic")}:</span> {data.pic.name}
            </div>
            {data.chemicalRelations && data.chemicalRelations.length ?
                <>
                    <div style={{ margin: "0.7rem auto" }}>
                        <span style={{ fontWeight: "bold" }}>{t("ChemicalRelations")}:</span>
                    </div>
                    <Table>
                        <TableHead>
                            <TableCell>{t("ChemicalName")}</TableCell>
                            {hasRole([UserRoles.CSR]) ? <TableCell>{t("Control")}</TableCell> : null}
                        </TableHead>
                        <TableBody>
                            {data.chemicalRelations.map(r => <TableRow key={r.relationId}>
                                <TableCell><Button variant="text" onClick={link(r.chemicalId)}>{r.chemicalName}</Button></TableCell>
                                {hasRole([UserRoles.CSR])
                                    ? <TableCell>
                                        <IconButton onClick={() => deleteRelation(r.relationId)}>
                                            <Delete />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                LocalStorageHandler.getHandler("toPrintQrCodes").addString(r.relationId);
                                                snackbar({ severity: "success", message: t("QrAddedToPrint") });
                                            }}
                                        >
                                            <QrCode2Sharp />
                                        </IconButton>
                                    </TableCell>
                                    : null}
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </>
                : null}
            {data.purchaseHistory.length !== 0 ?
                <div>
                    <div style={{ margin: "1rem auto" }}>
                        <span style={{ fontWeight: "bold" }}>{t("PurchaseHistory")}:</span>
                    </div>
                    <Table>
                        <TableHead sx={{ fontWeight: "bold" }}>
                            <TableRow>
                                <TableCell>{t("InternalOrderNumber")}</TableCell>
                                <TableCell>{t("ChemicalName")}</TableCell>
                                <TableCell>{t("AmountOrdered")}</TableCell>
                                <TableCell>{t("OrderedAt")}</TableCell>
                                <TableCell>{t("RequestorName")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.purchaseHistory.map(p => p.amountOrdered === null ? "" : <TableRow key={p.orderedAt + p.requestorName}>
                                <TableCell><Link to={"/application/" + p.id} >{p.internalOrderNumber}</Link></TableCell>
                                <TableCell>{p.chemicalName}</TableCell>
                                <TableCell>{p.amountOrdered}</TableCell>
                                <TableCell>{formatDateTime(p.orderedAt)}</TableCell>
                                <TableCell>{p.requestorName}</TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </div>
                : null}
        </Paper>
    );
}