import { useContext, useEffect, useState } from 'react';
import BoxMinusHeader from '../components/common/BoxMinusHeader';
import { fetchGet, hasRole, LayoutContext } from 'wcz-layout';
import ApplicationList from '../components/ApplicationList';
import { useQuery } from '@tanstack/react-query';
import RequestListRecordDto from '../dtos/RequestListRecordDto';
import { requestUrls } from '../utils/BaseUrl';
import Loading from '../components/common/Loading';
import { Navigate, useParams } from 'react-router-dom';
import { UserRoles } from '../utils/UserRoles';
import AccessDenied from './AccessDenied';

const supportedListTypes = ["OrderList", "ApplicationsForMe", "MyApplications"];

type ApplicationListParams = {
    type: string
}

export default function ApplicationListPage() {
    const { t, changeTitle } = useContext(LayoutContext);

    const { type } = useParams<ApplicationListParams>();

    useEffect(() => {
        changeTitle(t(type!));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [data, setData] = useState<RequestListRecordDto[] | null>(null);

    useQuery<RequestListRecordDto[], Response>(["orderList"], ({ signal }) => fetchGet(requestUrls.allOrders, signal), {
        onSuccess: (data) => {
            setData(data);
        },
        enabled: type === "OrderList"
    });
    useQuery<RequestListRecordDto[], Response>(["forMeApplicationList"], ({ signal }) => fetchGet(requestUrls.forMeRequests, signal), {
        onSuccess: (data) => {
            setData(data);
        },
        enabled: type === "ApplicationsForMe"
    });
    useQuery<RequestListRecordDto[], Response>(["ownApplicationList"], ({ signal }) => fetchGet(requestUrls.ownRequests, signal), {
        onSuccess: (data) => {
            setData(data);
        },
        enabled: type === "MyApplications"
    });

    if (!supportedListTypes.includes(type!)) {
        return <Navigate to="/" replace />;
    }

    if (!data)
        return <Loading />;

    if (!hasRole([UserRoles.CSR, UserRoles.BUYER, UserRoles.RECEPTION, UserRoles.WAREHOUSE])) {
        return <AccessDenied />;
    }

    return (
        <BoxMinusHeader>
            <ApplicationList rows={data} areOrders={type === "OrderList"} />
        </BoxMinusHeader>
    );
}