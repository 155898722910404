import React, { useContext } from 'react';
import { ArrayToString } from '../../../utils/StringUtils';
import EAModelDisplay from '../../my_form/extended_autocomplete/EAModelDisplay';
import { ExtendedAutocompleteModel, isExtendedAutocompleteModelArray } from '../../my_form/extended_autocomplete/ExtendedAutocomplete';
import styles from './AttributeDiv.module.css';
import { LayoutContext } from 'wcz-layout';

interface AttributeDivProps {
    labelTKey: string,
    value: string | string[] | number | number[] | boolean | undefined | ExtendedAutocompleteModel[] | null,
    outerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}

export default function AttributeDiv(props: AttributeDivProps) {
    const { t } = useContext(LayoutContext);
    const { value, labelTKey } = props;

    let displayValue;
    if (isExtendedAutocompleteModelArray(value)) {
        displayValue = <EAModelDisplay value={value} />;
    }
    else {
        displayValue = Array.isArray(value) ? ArrayToString(value)
            : value === undefined || value === null ? ""
                : typeof value === "boolean" ? t("YesNo-" + value)
                    : value.toString();
    }

    if (displayValue.toString().trim().length === 0)
        return null;


    const valueOnNewLine = typeof displayValue !== "string" || displayValue.length > 10;
    return <div {...props.outerProps}>
        <span className={styles.label}>{t(labelTKey)}:</span>&nbsp;
        <p className={valueOnNewLine ? styles.valueNewLine : styles.valueSameLine}>
            {displayValue}
        </p>
    </div>;
}

