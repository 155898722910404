import { isDevelopment } from 'wcz-layout';

let apiUrl: string = "https://api.dev.wistron.eu/chemical-control/";
let fileApiUrl: string = "https://api.dev.wistron.eu/file";
let peoplesoftUrl = "https://api.dev.wistron.eu/ps";
let fileUrl = "https://api.dev.wistron.eu/file";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu/chemical-control/";
    fileApiUrl = "https://api.wistron.eu/file";
    peoplesoftUrl = "https://api.wistron.eu/ps";
    fileUrl = "https://api.wistron.eu/file";
}

const requestControllerUrl = apiUrl + "v1/request/";
const requestUrls = {
    base: requestControllerUrl,
    allOrders: requestControllerUrl + "all_orders/",
    newRequest: requestControllerUrl + "new_request/",
    ownRequests: requestControllerUrl + "own_requests/",
    forMeRequests: requestControllerUrl + "for_me_requests/",
    detail: requestControllerUrl + "detail/",
    approve: requestControllerUrl + "approve/",
    edit: requestControllerUrl + "edit/",
    dashboard: requestControllerUrl + "dashboard/",
    withdraw: requestControllerUrl + "withdraw/",
    reject: requestControllerUrl + "reject/",
};

const locationControllerUrl = apiUrl + "v1/location/";
const locationUrls = {
    list: locationControllerUrl + "list/",
    detail: locationControllerUrl + "detail/",
    select: locationControllerUrl + "select/",
    delete: locationControllerUrl,
    update: locationControllerUrl + "update/",
    create: locationControllerUrl + "create/"
};

const chemicalControllerUrl = apiUrl + "v1/chemical/";
const chemicalUrls = {
    base: chemicalControllerUrl,
    list: chemicalControllerUrl + "list/",
    detail: chemicalControllerUrl + "detail/",
    select: chemicalControllerUrl + "select/",
    delete: chemicalControllerUrl,
    update: chemicalControllerUrl + "update/",
    casOptions: chemicalControllerUrl + "cas_options/",
    phrasesOptions: chemicalControllerUrl + "phrase_options/",
    createSentence: chemicalControllerUrl + "create_phrase/",
    labelData: chemicalControllerUrl + "label_data/",
    packagings: chemicalControllerUrl + "packagings/",
    newChemical: chemicalControllerUrl + "create/"
};

const chemicalLocationRelationControllerUrl = apiUrl + "v1/ch-l-relation/";
const chemicalLocationRelationUrls = {
    detail: chemicalLocationRelationControllerUrl + "detail/",
    multiple: chemicalLocationRelationControllerUrl + "multiple/",
    delete: chemicalLocationRelationControllerUrl
};

const orderControllerUrl = apiUrl + "v1/order/";
const orderUrls = {
    list: orderControllerUrl + "list/",
    detail: orderControllerUrl,
    delete: orderControllerUrl,
    newRequest: orderControllerUrl + "new_request/",
    approve: orderControllerUrl + "approve/",
    notifyArrival: orderControllerUrl + "notify_arrival/",
    notifyPickup: orderControllerUrl + "notify_pick_up/"
};

const adminControllerUrl = apiUrl + "v1/administration/";
const adminUrls = {
    svhcCas: adminControllerUrl + "svhc_cas/"
};

export { apiUrl, fileApiUrl, peoplesoftUrl, fileUrl, orderUrls, requestUrls, chemicalUrls, locationUrls, adminUrls, chemicalLocationRelationUrls };