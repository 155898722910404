import { Box } from "@mui/material";
import { GridColDef, GridSlotsComponent } from "@mui/x-data-grid-pro";
import { CapitalizeFirst } from "./StringUtils";

const getColumnDef = (fieldName: string, type?: string, options: Partial<GridColDef> = {} as GridColDef): GridColDef => {
    let returnObject: GridColDef = {
        field: fieldName,
        headerName: CapitalizeFirst(fieldName),
        valueGetter: (params) => {
            if ((params.colDef.headerName === "Hazard class" || params.colDef.headerName === "Třída nebezpečnosti") && params.value === "I.") {
                return params.value + "LowBoiling";
            }
            return params.value;
        },
        flex: 1,
        minWidth: 150,
        maxWidth: 1000,
        editable: true,
    };
    if (type)
        returnObject = {
            ...returnObject,
            type: type
        };
    if (type === "boolean") {
        returnObject = {
            ...returnObject,
            renderCell: (params) => {
                if (params.value === undefined || params.value === null)
                    return "";
                return params.value ? "Yes" : "No";
            }
        };
    }
    returnObject = {
        ...returnObject,
        ...options
    };
    return returnObject;
};

const noRowsCtor = () => {
    return (
        <Box style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute" }}>
            NoDataToDisplay
        </Box>
    );
};

const defaultDataGridComponents: Partial<GridSlotsComponent> = {
    NoRowsOverlay: noRowsCtor
};

export { defaultDataGridComponents, getColumnDef };
