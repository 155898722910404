import { FC, ReactNode, useState } from 'react';
import PackagingDto from '../../dtos/PackagingDto';
import NewChemicalModel, { GetCleanNewChemicalModel } from '../../models/NewChemicalModel';
import { NewApplicationContext } from '../../utils/NewApplicationContext';

interface NewApplicationProviderProps {
    children: ReactNode;
}

export const NewApplicationProvider: FC<NewApplicationProviderProps> = ({ children }) => {
    const isChemicalValidState = useState(false);
    const isLocationValidState = useState(false);
    const isPackagingValidState = useState(false);
    const chemicalSelectedId = useState(" ");
    const locationSelectedId = useState(" ");
    const packagingSelectedId = useState<string>("new");
    const chemicalState = useState(GetCleanNewChemicalModel() as NewChemicalModel);
    const locationState = useState({
        name: "",
        pic: {
            label: "",
            id: ""
        }
    });
    const packagingState = useState({} as PackagingDto);
    const packagingQuantityState = useState(0);

    return (
        <NewApplicationContext.Provider
            value={{
                chemical: {
                    isFormValidState: isChemicalValidState,
                    selectedIdState: chemicalSelectedId,
                    modelState: chemicalState
                },
                location: {
                    isFormValidState: isLocationValidState,
                    selectedIdState: locationSelectedId,
                    modelState: locationState
                },
                packaging: {
                    isFormValidState: isPackagingValidState,
                    selectedIdState: packagingSelectedId,
                    modelState: packagingState
                },
                quantityState: packagingQuantityState
            }}
        >
            {children}
        </NewApplicationContext.Provider>
    );
};