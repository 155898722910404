import { useContext, useEffect, useState } from 'react';
import { Button, Divider, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import LocationForm from './LocationForm';
import { fetchGet, LayoutContext } from 'wcz-layout';
import { NewApplicationContext } from '../../utils/NewApplicationContext';
import SelectDto from '../../dtos/SelectDto';
import { useQuery } from '@tanstack/react-query';
import { locationUrls } from '../../utils/BaseUrl';

export default function LocationSection() {
    const { t } = useContext(LayoutContext);
    const newApplicationContext = useContext(NewApplicationContext);
    const [locationId, setLocationId] = newApplicationContext.location.selectedIdState;
    const [formVisible, setFormVisible] = useState(false);

    useEffect(() => {
        if (locationId === "new")
            setFormVisible(true);
        else
            setFormVisible(false);
    }, [locationId]);

    const { data: locations } = useQuery<SelectDto[], Response>(["locationSelect"], ({ signal }) => fetchGet(locationUrls.select, signal));

    // const { refetch: getLocation } = useQuery<LocationListDto, Response>(["locationDetail", id], ({ signal }) => fetchGet(locationUrls.detail + id, signal))

    const handleLocationSelect = (e: SelectChangeEvent<string>) => {
        setLocationId(e.target.value);
        // setLocation(locations.find((location) => location.id === e.target.value)
        //     ??
        //     { name: "", pic: { label: `${user.name} (${user.department})`, id: user.id } })
    };

    if (!locations) {
        return <>Loading...</>;
    }

    return (
        <Paper className="section-paper">
            <Typography sx={{ m: 2, fontWeight: "bold", fontSize: 20 }}>{t("Location")}</Typography>
            <Select fullWidth value={locationId} onChange={handleLocationSelect} sx={{ marginBottom: "1.5em" }}>
                <MenuItem value=" " sx={{ display: 'none' }}>{t("ChooseLocation")}</MenuItem>
                <MenuItem value="new">{t("New(Z.Rod)")}</MenuItem>
                <Divider />
                {locations.map((location) => {
                    return <MenuItem value={location.id} key={location.id}>{location.name}</MenuItem>;
                })}
            </Select>
            {locationId !== " " && (formVisible
                ? (<>
                    {"new" !== locationId && <Button onClick={() => { setFormVisible(false); }}>{t("HideDetails")}</Button>}
                    <LocationForm />
                </>)
                : <Button onClick={() => { setFormVisible(true); }}>{t("DisplayDetails")}</Button>)}
        </Paper>
    );
}