import AttributeDiv from '../../common/AttributeDiv/AttributeDiv';
import HideableContent from '../../common/HideableContent/HideableContent';
import Loading from '../../common/Loading';
import ChemicalDto from '../../../dtos/ChemicalDto';
import { default as attributeDivStyles } from './../../common/AttributeDiv/AttributeDiv.module.css';
import FileLink from '../../file/FileLink';
import { Box } from '@mui/material';
import styles from './ChemicalDetail.module.css';
import { DeleteOutline, InfoOutlined, LocalFireDepartment, LocalHospital, Masks, ScienceOutlined, SportsHandball } from '@mui/icons-material';
import { useContext } from 'react';
import { LayoutContext } from 'wcz-layout';

interface ChemicalDetailProps {
    chemical: ChemicalDto,
    showBlFile?: boolean
    application?: boolean
}

export default function ChemicalDetail(props: ChemicalDetailProps) {
    const { t } = useContext(LayoutContext);
    const sortedPSentences = props.chemical.psentences.sort((a, b) => a.shortText.localeCompare(b.shortText));
    const sortedHSentences = props.chemical.hsentences.sort((a, b) => a.shortText.localeCompare(b.shortText));

    if (!props.chemical)
        return <Loading />;

    return (
        <>
            {props.showBlFile
                ? <Box sx={{ float: "right" }} className={styles.blContainer}>
                    <span className={[attributeDivStyles.label, styles.blLabel].join(" ")}>{t("BlFile")}</span>
                    <FileLink subId={props.chemical.id} big />
                </Box>
                : null}
            <div className={styles.chemicalPaper}>
                <h2 className={attributeDivStyles.label}>{props.chemical.name}</h2>
                <div style={{ marginBottom: "10px" }}><span className={attributeDivStyles.label}>{t("Supplier")}:</span> {props.chemical.supplier}</div>
                <div style={{ lineHeight: "50px" }} hidden={props.chemical.ghsSymbol.length === 0}>
                    <span className={attributeDivStyles.label}>{t("GhsSymbol")}:</span>
                    {props.chemical.ghsSymbol ? props.chemical.ghsSymbol.map((symbol, i, { length }) => {
                        return <span key={symbol} className={styles.nobr}>
                            <img alt="ghs icon" src={`/img/${symbol.toLowerCase()}.png`} height="40px" style={{ verticalAlign: "middle" }} /> {symbol}{i + 1 === length ? null : ","}
                        </span>;
                    }) : null}
                </div>
                <AttributeDiv labelTKey={"Hsentences"} value={sortedHSentences} outerProps={{
                    style: {
                        paddingBottom: "0.4em"
                    }
                }} />
                <AttributeDiv labelTKey={"Psentences"} value={sortedPSentences} />
                <HideableContent
                    title={<><LocalHospital />&nbsp;{t("FirstAid")}</>}
                    outerProps={{
                        sx: {
                            backgroundColor: "#00ff0728",
                            marginTop: "1rem !important",
                            display: props.chemical.firstAidGeneral
                                || props.chemical.firstAidInhalation
                                || props.chemical.firstAidIngestion
                                || props.chemical.firstAidSkinContact
                                || props.chemical.firstAidEyeContact
                                ? undefined
                                : "none"
                        }
                    }}>
                    <AttributeDiv labelTKey={"FAGeneral"} value={props.chemical.firstAidGeneral} />
                    <AttributeDiv labelTKey={"FAInhalation"} value={props.chemical.firstAidInhalation} />
                    <AttributeDiv labelTKey={"FASkinContact"} value={props.chemical.firstAidSkinContact} />
                    <AttributeDiv labelTKey={"FAEyeContact"} value={props.chemical.firstAidEyeContact} />
                    <AttributeDiv labelTKey={"FAIngestion"} value={props.chemical.firstAidIngestion} />
                </HideableContent>
                <HideableContent title={<><LocalFireDepartment />&nbsp;{t("FireExtinguishers")}</>} outerProps={{
                    sx: {
                        backgroundColor: "#ff000038",
                        display: props.chemical.fireExtinguisherSuitable
                            || props.chemical.fireExtinguisherNonSuitable
                            ? undefined
                            : "none"
                    }
                }}>
                    <AttributeDiv labelTKey={"FireExtinguisherSuitable"} value={props.chemical.fireExtinguisherSuitable} />
                    <AttributeDiv labelTKey={"FireExtinguisherNonSuitable"} value={props.chemical.fireExtinguisherNonSuitable} />
                </HideableContent>
                <HideableContent title={<><Masks />&nbsp;{t("BodyProtection")}</>} outerProps={{
                    sx: {
                        backgroundColor: "#b840ff24",
                        display: props.chemical.protectionAirways
                            || props.chemical.protectionHands
                            || props.chemical.protectionEyes
                            || props.chemical.protectionSkinAndBody
                            ? undefined
                            : "none"
                    }
                }}>
                    <AttributeDiv labelTKey={"ProtectionAirways"} value={props.chemical.protectionAirways} />
                    <AttributeDiv labelTKey={"ProtectionHands"} value={props.chemical.protectionHands} />
                    <AttributeDiv labelTKey={"ProtectionEyes"} value={props.chemical.protectionEyes} />
                    <AttributeDiv labelTKey={"ProtectionSkinAndBody"} value={props.chemical.protectionSkinAndBody} />
                </HideableContent>
                <HideableContent title={<><DeleteOutline />&nbsp;{t("DisposalConsiderations")}</>} outerProps={{
                    sx: {
                        backgroundColor: "#bfb09f40",
                        display: props.chemical.wasteTypeCode
                            || props.chemical.wasteType
                            || props.chemical.wasteSubgroup
                            || props.chemical.wasteGroup
                            ? undefined
                            : "none"
                    }
                }}>
                    <AttributeDiv labelTKey={"WasteTypeCode"} value={props.chemical.wasteTypeCode} />
                    <AttributeDiv labelTKey={"WasteType"} value={props.chemical.wasteType} />
                    <AttributeDiv labelTKey={"WasteSubgroup"} value={props.chemical.wasteSubgroup} />
                    <AttributeDiv labelTKey={"WasteGroup"} value={props.chemical.wasteGroup} />
                </HideableContent>
                <HideableContent title={<><ScienceOutlined />&nbsp;{t("PhysicalChemicalAttributes")}</>} outerProps={{
                    sx: {
                        backgroundColor: "#ffff4430",
                        display: props.chemical.density != null
                            || props.chemical.state != null
                            || props.chemical.flashpoint != null
                            || props.chemical.hazardClass != null
                            || props.chemical.voc != null
                            ? undefined
                            : "none"
                    }
                }}>
                    <AttributeDiv labelTKey="Density" value={props.chemical.density} />
                    <AttributeDiv labelTKey="State" value={props.chemical.state} />
                    <AttributeDiv labelTKey={"Flashpoint"} value={props.chemical.flashpoint + "°C"} />
                    <AttributeDiv labelTKey="HazardClass" value={props.chemical.hazardClass} />
                    <AttributeDiv labelTKey="Voc" value={props.chemical.voc} />
                </HideableContent>
                <HideableContent title={<><SportsHandball />&nbsp;{t("ManipulationAndStorage")}</>} outerProps={{
                    sx: {
                        backgroundColor: "#ff8a65",
                        display: props.chemical.manipulation != null
                            || props.chemical.storage != null
                            ? undefined
                            : "none"
                    }
                }}>
                    <AttributeDiv labelTKey="Manipulation" value={props.chemical.manipulation} />
                    <AttributeDiv labelTKey="Storage" value={props.chemical.storage} />
                </HideableContent>
                <HideableContent title={<><InfoOutlined />&nbsp;{t("Others")}</>} outerProps={{
                    sx: {
                        backgroundColor: "#4ef2ff30"
                    }
                }}>
                    <AttributeDiv labelTKey="DangerousSubstanceCategories" value={props.chemical.dangerousSubstanceCategories} />
                    <AttributeDiv labelTKey="IsActive" value={props.chemical.isActive} outerProps={{
                        className: styles.smallLineBreak
                    }} />
                    <AttributeDiv labelTKey="SvhcSubstance" value={props.chemical.svhcSubstance} />
                    <AttributeDiv labelTKey="BlNumber" value={props.chemical.blNumber} />
                    <AttributeDiv labelTKey="BlPublicationDate" value={props.chemical.blPublicationDate} />
                    <AttributeDiv labelTKey="Cas" value={props.chemical.cas} outerProps={{
                        className: styles.smallLineBreak
                    }} />
                    <AttributeDiv labelTKey="DangerousByWaterLaw" value={props.chemical.dangerousByWaterLaw} />
                    <AttributeDiv labelTKey="ExtraDangerousByWaterLaw" value={props.chemical.extraDangerousByWaterLaw} outerProps={{
                        className: styles.smallLineBreak
                    }} />
                    <AttributeDiv labelTKey="RestrictedForPregnants" value={props.chemical.restrictedForPregnants} />
                    <AttributeDiv labelTKey="RestrictedForYouthful" value={props.chemical.restrictedForYouthful} />
                    <AttributeDiv labelTKey="KhsRulesRecommended" value={props.chemical.khsRulesRecommended} />
                </HideableContent>
            </div>
        </>
    );
}