import { Info } from '@mui/icons-material';
import { DataGridPro, GridActionsCellItem, GridCellParams, GridColDef, GridToolbarProps } from '@mui/x-data-grid-pro';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridToolbar } from 'wcz-x-data-grid';
import RequestListRecordDto from '../dtos/RequestListRecordDto';
import { getColumnDef } from '../utils/RenderUtils';
import { formatDateTime, HumanLangFromConstantCase } from '../utils/StringUtils';
import { LayoutContext } from 'wcz-layout';

interface ApplicationListProps {
    rows: RequestListRecordDto[],
    areOrders?: boolean
}

export default function ApplicationList(props: ApplicationListProps) {
    const { t } = useContext(LayoutContext);
    const navigate = useNavigate();

    const getColumnDefNoneditable = (fieldName: string, type?: string, options: Partial<GridColDef> = {} as GridColDef) => {
        return getColumnDef(fieldName, type, {
            ...options,
            editable: false
        });
    };

    const columns: GridColDef[] = [{
        field: 'actions', type: 'actions', width: 50,
        getActions: (params: any) => [
            <GridActionsCellItem
                key="info"
                icon={<Info />}
                label={t("Detail")}
                onClick={() => navigate(`/application/${params.id}`)}
            />,
        ]
    },
    ...(props.areOrders ? [getColumnDefNoneditable("internalOrderNumber")] : []),
    getColumnDefNoneditable("waitingStatus", undefined, {
        renderCell: (params) => {
            return <>{HumanLangFromConstantCase(params.value)}</>;
        }
    }),
    getColumnDefNoneditable("requestorEmployeeName"),
    getColumnDefNoneditable("chemicalName"),
    getColumnDefNoneditable("createdDate", undefined, {
        renderCell: (params) => {
            return <>{formatDateTime(params.value)}</>;
        }
    }),
    ];

    const doubleClick = useCallback((params: GridCellParams) => {
        navigate(`/application/${params.id}`);
    }, [navigate]);

    return (
        <DataGridPro columns={columns} rows={props.rows} components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {} as GridToolbarProps
            }}
            onCellDoubleClick={doubleClick}
        />
    );
}