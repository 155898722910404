import { useContext } from 'react';
import { LayoutContext } from 'wcz-layout';
import NewLocationDto from '../../../dtos/NewLocationDto';

interface LocationDetailProps {
    location: NewLocationDto
}

export default function LocationDetail(props: LocationDetailProps) {
    const { t } = useContext(LayoutContext);

    return (
        <>
            <div>
                <span style={{ fontWeight: "bold" }}>{t("Name")}:</span> {props.location.name}
            </div>
            <div>
                <span style={{ fontWeight: "bold" }}>{t("Pic")}:</span> {props.location.picName}
            </div>
        </>
    );
}