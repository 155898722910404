import React from 'react';
import { Menu, MenuItem, PopoverPosition, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import QRCode from 'react-qr-code';
import RelationForQrDto from '../../dtos/ChemicalLocationRelationDto';
import { QR_ADDRESS } from '../../utils/QrConsts';
import LocalStorageHandler from '../../utils/LocalStorageHandler';

interface QrsToPrintProps {
    relalationsState: [RelationForQrDto[], React.Dispatch<React.SetStateAction<RelationForQrDto[]>>]
    qrSize: number,

}

interface QrsToPrintState {
    anchorPosition: PopoverPosition | null,
    indexForMenu: null | number
}

export default class QrsToPrint extends React.Component<QrsToPrintProps, QrsToPrintState> {
    state: QrsToPrintState = {
        anchorPosition: null,
        indexForMenu: null
    };
    storageHandler: LocalStorageHandler;

    constructor(props: QrsToPrintProps) {
        super(props);
        this.storageHandler = LocalStorageHandler.getHandler("toPrintQrCodes");
    }

    private getIdByIndex = (index: number): string => {
        return this.props.relalationsState[0][index].id;
    };

    private openContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            ...this.state,
            anchorPosition: { left: e.clientX, top: e.clientY },
            indexForMenu: parseInt(e.currentTarget.id)
        });
    };

    private closeMenu = () => {
        this.setState({
            ...this.state,
            anchorPosition: null,
            indexForMenu: null
        });
    };

    private deleteItem = () => {
        if (this.state.indexForMenu === null)
            return;
        const toRemoveId = this.getIdByIndex(this.state.indexForMenu);
        this.storageHandler.removeString(toRemoveId);
        let newRelations = [...this.props.relalationsState[0]];
        newRelations.splice(this.state.indexForMenu, 1);
        this.props.relalationsState[1](newRelations);
        this.closeMenu();
    };

    private duplicateItem = () => {
        if (this.state.indexForMenu === null)
            return;
        const toAddId = this.getIdByIndex(this.state.indexForMenu);
        this.storageHandler.addString(toAddId);
        let newRelations = [...this.props.relalationsState[0]];
        const toAdd = newRelations.find(r => r?.id === toAddId) as RelationForQrDto;
        newRelations.push(toAdd);
        this.props.relalationsState[1](newRelations);
        this.closeMenu();
    };

    render() {
        const qrSizePx = this.props.qrSize * 37.795276;
        return <Box
            style={{
                display: "flex",
                flexWrap: "wrap"
            }}
        >
            <Menu
                open={Boolean(this.state.anchorPosition)}
                onClose={this.closeMenu}
                anchorReference={"anchorPosition"}
                anchorPosition={this.state.anchorPosition ?? undefined}
            >
                <MenuItem onClick={this.deleteItem}>Delete</MenuItem>
                <MenuItem onClick={this.duplicateItem}>Duplicate</MenuItem>
            </Menu>
            {this.props.relalationsState[0].map((relation, i) =>
                <Tooltip
                    key={i}
                    title={relation.id}
                    id={relation.id}
                >
                    <Box
                        style={{ display: "inline-block", margin: "1rem", maxWidth: qrSizePx }}
                        id={i.toString()}
                        onContextMenu={this.openContextMenu}
                    >
                        <QRCode
                            size={qrSizePx}
                            id={relation.id}
                            value={QR_ADDRESS + relation.id}
                        />
                        <ul style={{ listStyle: "none", padding: 0, fontSize: (0.003 * qrSizePx + 0.4) + "rem" }}>
                            <li>
                                {relation.chemicalName}
                            </li>
                            <li>
                                {relation.locationName}
                            </li>
                        </ul>
                    </Box>
                </Tooltip>
            )}
        </Box>;
    }
}