import { useContext, useEffect, useState } from 'react';
import { Box, Paper, Table, TableCell, TableHead, TableRow } from '@mui/material';
import ApplicationDetailDto from '../dtos/ApplicationDetailDto';
import { fetchGet, hasRole, LayoutContext } from 'wcz-layout';
import { useParams } from 'react-router-dom';
import ApplicationDetailDynamic from '../components/application/detail/ApplicationDetailDynamic';
import { useQuery } from '@tanstack/react-query';
import { requestUrls } from '../utils/BaseUrl';
import { formatDateTime } from '../utils/StringUtils';
import Loading from '../components/common/Loading';
import { UserRoles } from '../utils/UserRoles';
import AccessDenied from './AccessDenied';

type ParamTypes = {
    id: string
}

export default function ApplicationDetailPage() {
    const { changeTitle, t, } = useContext(LayoutContext);

    useEffect(() => {
        changeTitle(t("ApplicationDetail"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [model, setModel] = useState<ApplicationDetailDto>({} as ApplicationDetailDto);

    const { id } = useParams<ParamTypes>();
    const { refetch } = useQuery<ApplicationDetailDto>(["applicationDetail"], ({ signal }) => fetchGet(requestUrls.detail + id, signal), {
        onSuccess: (data: ApplicationDetailDto) => {
            setModel(data);
        }
    });

    if (!model || !Object.keys(model).length) {
        return <Loading />;
    }

    if (!hasRole([UserRoles.CSR, UserRoles.BUYER, UserRoles.RECEPTION, UserRoles.WAREHOUSE])) {
        return <AccessDenied />;
    }

    return (
        <Box sx={{ margin: { xs: "0em auto", md: "1em auto" }, padding: "1em", maxWidth: "800px" }}>
            <Paper style={{ padding: "1.5rem" }}>
                {model.internalOrderNumber
                    ? <>
                        <h3><span style={{ fontWeight: "bold" }}>{t("InternalOrderNumber")}:</span> {model.internalOrderNumber}</h3>
                        <div><span style={{ fontWeight: "bold" }}>{t("ChemicalName")}:</span> {model.chemical.name}</div>
                    </>
                    : <h4>{`${t("ChemicalName")}: ${model.chemical.name}`}</h4>}
                <div><span style={{ fontWeight: "bold" }}>{t("WaitingStatus")}:</span> {t(model.waitingStatus)}</div>
                {model.receivedAt
                    ? <div><span style={{ fontWeight: "bold" }}>{t("ReceivedAt")}:</span> {model.receivedAt}</div>
                    : null}
                {model.pickedUpBy
                    ? <div><span style={{ fontWeight: "bold" }}>{t("PickedUpBy")}:</span> {model.pickedUpBy.label}</div>
                    : null}
                <div><span style={{ fontWeight: "bold" }}>{t("RequestorEmployeeName")}:</span> {model.requestorEmployeeName}</div>
                {model?.history?.length
                    ? <>
                        <span style={{ fontWeight: "bold" }}>{t("RequestHistory")}:</span>
                        <Table sx={{ marginBottom: "1.5rem" }}>
                            <TableHead sx={{ fontWeight: "bold" }}>
                                <TableCell>{t("MadeAt")}</TableCell>
                                <TableCell>{t("Approver")}</TableCell>
                                <TableCell>{t("ByRole")}</TableCell>
                                <TableCell>{t("Action")}</TableCell>
                                <TableCell>{t("Notice/Reason")}</TableCell>
                            </TableHead>
                            {model.history.map(h =>
                                <TableRow key={h.madeAt}>
                                    <TableCell>{formatDateTime(h.madeAt)}</TableCell>
                                    <TableCell>{h.approver}</TableCell>
                                    <TableCell>{h.byRole}</TableCell>
                                    <TableCell>{h.action}</TableCell>
                                    <TableCell>{h.notice}</TableCell>
                                </TableRow>)}
                        </Table>
                    </> : null}
                <ApplicationDetailDynamic
                    applicationModel={model}
                    refetchModel={refetch} />
            </Paper>
        </Box>
    );
}