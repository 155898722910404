import { useContext, useState, FormEvent } from 'react';
import { Button, Divider, FormControl, Input, InputLabel, Link, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import OrderForm from './OrderForm';
import { fetchGet, LayoutContext } from 'wcz-layout';
import { isValidHttpUrl } from '../../utils/StringUtils';
import { OpenInNew } from '@mui/icons-material';
import PackagingDto from '../../dtos/PackagingDto';
import Loading from '../common/Loading';
import { NewApplicationContext } from '../../utils/NewApplicationContext';
import { chemicalUrls } from '../../utils/BaseUrl';
import { useQuery } from '@tanstack/react-query';

export default function OrderSection() {
    const newApplicationContext = useContext(NewApplicationContext);
    const [quantity, setQuantity] = newApplicationContext.quantityState;
    const packagingStates = newApplicationContext.packaging;
    const packagingState = packagingStates.modelState;
    const [selectedPackagingId, setSelectedPackagingId] = packagingStates.selectedIdState;
    const chemicalId = newApplicationContext.chemical.selectedIdState[0];
    const { snackbar, t } = useContext(LayoutContext);

    const [isUrlEditing, setIsUrlEditing] = useState<boolean>(false);

    const { data: packagings = [] } = useQuery<PackagingDto[], Response>(["packagingsSelect",], ({ signal }) => fetchGet(chemicalUrls.packagings + chemicalId, signal), {
        enabled: chemicalId !== "new"
    });

    const handleSaveLink = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newLink = (e.target as any).elements["linkInput"].value;
        if (!isValidHttpUrl(newLink)) {
            snackbar({ message: t("InvalidLink"), severity: "error" });
            return;
        }
        packagingState[1](p => ({
            ...p,
            link: (e.target as any).elements["linkInput"].value,
            lastUpdate: t("Now")
        }));
        setIsUrlEditing(false);
    };

    const handlePackagingSelect = (e: SelectChangeEvent<string>) => {
        setIsUrlEditing(false);
        setSelectedPackagingId(e.target.value);
        if (e.target.value !== "new") {
            packagingState[1](packagings?.find(p => p.id === e.target.value)!);
        }
    };

    if (packagings === null) {
        return <Loading />;
    }

    return <>
        <Paper className="section-paper">
            <Typography sx={{ m: 2, fontWeight: "bold", fontSize: 20 }}>{t("Order")}</Typography>
            <h4 style={{ marginBottom: 0 }}>{t("Packaging")}</h4>
            <Select
                value={selectedPackagingId}
                onChange={handlePackagingSelect}
                fullWidth
                sx={{ marginBottom: "0.5em" }}
            >
                <MenuItem value={"new"}>{t("New(St.Rod)")}</MenuItem>
                <Divider />
                {packagings.map((packaging) => {
                    return <MenuItem value={packaging.id} key={packaging.id}>{packaging.amount === null ? "0" : packaging.amount + " " + packaging.unit === null ? "" : packaging.unit}</MenuItem>;
                })}
            </Select>
            {selectedPackagingId && selectedPackagingId !== "new"
                ? <div>
                    <span>Link:</span>
                    <Link href={packagingState[0].link} target="_blank" rel="noopener noreferrer">
                        {packagingState[0].link} <OpenInNew fontSize={"small"} />
                    </Link>
                    {" "}
                    <span>{t("LastChanged")}: {packagingState[0].lastUpdate}</span>
                    {isUrlEditing
                        ? <form onSubmit={handleSaveLink}>
                            <Input name="linkInput" id="linkInput" fullWidth />
                            <Button color="error" onClick={() => { setIsUrlEditing(false); }}>
                                {t("Cancel")}
                            </Button>
                            <Button type="submit">
                                {t("Save")}
                            </Button>
                        </form>
                        : <Button onClick={() => setIsUrlEditing(true)} hidden={isUrlEditing}>
                            {t("UpdateLink")}
                        </Button>}
                </div>
                : <OrderForm />}

            <Divider sx={{ margin: "0.7em 0 1em" }} />
            <FormControl fullWidth sx={{ margin: "1em 0 1em" }}>
                <InputLabel sx={{ fontWeight: "bold" }}>{t("PackagingQuantity")}: </InputLabel>
                <Input type="number" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} />
            </FormControl>
            <div>
                <span style={{ fontWeight: "bold" }}>{t("TotalAmount")}:</span>
                {" "}
                {(!Number.isNaN(quantity) ? quantity : 0) * (packagingState[0].amount ?? 0)} {packagingState[0].unit}
            </div>
        </Paper>
    </>;
}