import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DataGridPro, GridActionsCellItem, GridCellParams, GridColDef, GridRowId, GridRowModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { fetchDelete, fetchGet, fetchPut, hasRole, LayoutContext } from "wcz-layout";
import { getColumnDef } from "../utils/RenderUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { locationUrls, peoplesoftUrl } from "../utils/BaseUrl";
import LocationDto from "../dtos/LocationDto";
import { GridToolbarProps, AutocompleteEditInputCell, TableContainer } from 'wcz-x-data-grid';
import { Add, Delete, Info } from "@mui/icons-material";
import PeopleModel from "../models/PeopleModel";
import { uniqBy } from "lodash";
import LocationUpdateDto from "../dtos/LocationUpdateDto";
import { UserRoles } from "../utils/UserRoles";
import { useNavigate } from "react-router-dom";
import AccessDenied from "./AccessDenied";
import { Button } from "@mui/material";

export default function LocationList() {
    const { changeTitle, t, snackbar } = useContext(LayoutContext);
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([] as { name: string, id: string }[]);

    useEffect(() => {
        changeTitle(t("LocationList"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useQuery<PeopleModel[]>(["employees"], ({ signal }) => fetchGet(`${peoplesoftUrl}/v1/employee?search=status==Active&fields=nameTitleCase,employeeId,departmentId`, signal), {
        onSuccess: (data: PeopleModel[]) => {
            const uniqueUsers = uniqBy(data, 'nameTitleCase');
            setEmployees(uniqueUsers.map(u => {
                return { id: u.employeeId, name: `${u.nameTitleCase} (${u.departmentId})` };
            }));
        }
    });

    const { data = [], refetch } = useQuery<LocationDto[]>(["locationList"], ({ signal }) => fetchGet(locationUrls.list, signal));

    const { mutate: deleteLocation } = useMutation((id: GridRowId) =>
        fetchDelete(locationUrls.delete + id),
        {
            onSuccess: () => {
                snackbar({ message: t("Location") + " " + t("deleted"), severity: 'success' });
                refetch();
            }
        }
    );

    const updateLocation = useMutation((location: LocationUpdateDto) => fetchPut(`${locationUrls.update}/${location.id}`, location));

    const processRowUpdate = async (row: GridRowModel<LocationDto>): Promise<LocationDto> => {
        updateLocation.mutateAsync({
            id: row.id,
            name: row.name,
            picId: row.pic.id,
            picName: row.pic.name
        });
        return row;
    };

    const getColumnDefCsrEditable = (fieldName: string, type?: string, options: Partial<GridColDef> = {} as GridColDef): GridColDef => {
        return getColumnDef(fieldName, type, {
            ...options,
            editable: hasRole([UserRoles.CSR]) && (!options?.editable || options.editable)
        });
    };

    const doubleClick = useCallback((params: GridCellParams) => {
        navigate(`/location/${params.id}`);
    }, [navigate]);

    const actions: GridColDef = useMemo<GridColDef>(() => {
        return {
            field: 'actions', type: 'actions', width: 80,
            getActions: (params: any) => [
                <GridActionsCellItem
                    icon={<Info />}
                    label={t("Detail")}
                    onClick={() => navigate(`/location/${params.id}`)}
                />,
                <GridActionsCellItem
                    icon={<Delete />}
                    label={t("Delete")}
                    onClick={() => deleteLocation(params.id)}
                />,
            ]
        };
    }, [deleteLocation, navigate]);

    const handleAddNewLocation = useCallback(() => {
        navigate("/create_location");
    }, [navigate]);

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
                {hasRole([UserRoles.CSR]) && <Button size="small" type="button" color="primary" onClick={handleAddNewLocation} startIcon={<Add />}>{t("AddNewLocation")}</Button>}
            </GridToolbarContainer>
        );
    };

    const dataColumns: GridColDef[] = useMemo<GridColDef[]>(() => [
        getColumnDefCsrEditable("name"),
        getColumnDefCsrEditable("pic", undefined, {
            headerName: t("PicEmployeeName"),
            renderEditCell: params => <AutocompleteEditInputCell params={params} options={employees} getOptionLabel={o => o.name} />,
            valueFormatter: ({ value }) => value.name
        })
    ], [employees]);

    let columns = hasRole([UserRoles.CSR]) ? [actions] : [];
    columns.push(...dataColumns);

    if (!hasRole([UserRoles.CSR, UserRoles.BUYER, UserRoles.RECEPTION, UserRoles.WAREHOUSE])) {
        return <AccessDenied />;
    }

    return (
        <TableContainer>
            <DataGridPro rows={data} columns={columns} components={{ Toolbar: customToolbar }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "name", sort: "asc" }]
                    }
                }}
                slotProps={{
                    toolbar: {
                        hideAddRecord: true
                    } as GridToolbarProps,
                }}
                editMode="row"
                processRowUpdate={processRowUpdate}
                onCellDoubleClick={doubleClick}
            />
        </TableContainer>
    );
}