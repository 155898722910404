import { Box, Button, FormControl, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { fetchPost, hasRole, LayoutContext } from "wcz-layout";
import { PhraseDto } from "../dtos/PhraseDto";
import { chemicalUrls } from "../utils/BaseUrl";
import AccessDenied from "./AccessDenied";
import { UserRoles } from "../utils/UserRoles";

export default function CreateSentence() {
    const { changeTitle, t, snackbar } = useContext(LayoutContext);

    const [phrase, setPhrase] = useState<PhraseDto>({
        id: "",
        fullText: "",
        type: "P",
        paramsInfo: []
    });

    const { mutate } = useMutation((data: PhraseDto) => fetchPost(chemicalUrls.createSentence, data),
        {
            onSuccess: () => {
                snackbar({ message: t("PhraseCreated"), severity: 'success' });
                setPhrase({
                    id: "",
                    fullText: "",
                    type: "",
                    paramsInfo: []
                });
            }
        });

    useEffect(() => {
        changeTitle(t("CreatePhrase"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPhrase(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const handleSelectChange = (e: SelectChangeEvent) => {
        setPhrase(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const handleSubmit = () => mutate(phrase);

    if (!hasRole([UserRoles.CSR])) {
        return <AccessDenied />;
    };

    return (
        <Box sx={{ mt: 5, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <FormControl size="small">
                <Typography sx={{ fontSize: 12 }}>{t("PhraseType")}</Typography>
                <Select sx={{ mb: 5, width: 150 }} name="type" value={phrase.type} onChange={handleSelectChange}>
                    <MenuItem value={"P"}>{"P"}</MenuItem>
                    <MenuItem value={"H"}>{"H"}</MenuItem>
                </Select>
            </FormControl>
            <TextField sx={{ mb: 5, width: 300 }} onChange={handleChange} name="id" label={t("PhraseName")} value={phrase.id} placeholder={t("PhraseExample")} required />
            <TextField sx={{ mb: 5, width: 300 }} onChange={handleChange} name="fullText" label={t("FullText")} value={phrase.fullText} multiline required />
            <TextField sx={{ mb: 5, width: 300 }} onChange={handleChange} name="paramsInfo" label={t("ParamsInfo")} value={phrase.paramsInfo} multiline />
            <Button sx={{ mb: 3 }} type="button" onClick={handleSubmit}>{t("CreatePhrase")}</Button>
        </Box>
    );
}