import { AdminPanelSettings, Create, Home, LocationOn, Notes, QrCode2Sharp, Science } from '@mui/icons-material';
import { green, purple } from '@mui/material/colors';
import { useMemo } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { hasRole, LayoutProvider, LeftDrawerItem, User, useUser } from 'wcz-layout';
import packageJson from '../package.json';
import ApplicationDetailPage from './pages/ApplicationDetailPage';
import ApplicationListPage from './pages/ApplicationListPage';
import CasAdmin from './pages/CasAdmin';
import ChemicalDetailPage from './pages/ChemicalDetailPage/ChemicalDetailPage';
import ChemicalList from './pages/ChemicalList';
import CreateChemicalPage from './pages/CreateChemicalPage';
import CreateLocationPage from './pages/CreateLocationPage';
import CreateSentence from './pages/CreateSentence';
import HomePage from './pages/Home';
import LabelPrinting from './pages/LabelPrinting';
import LocationDetailPage from './pages/LocationDetailPage';
import LocationList from './pages/LocationList';
import NewApplicationPage from './pages/NewApplicationPage';
import QrLandingPage from './pages/QrLandingPage';
import QrPrinting from './pages/QrPrinting';
import { UserRoles } from './utils/UserRoles';

export default function App() {
    const user: User = useUser();

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Layout.Home", path: "/", icon: <Home fontSize="large" /> },
        { title: "ApprovedChemicalsInWcz", path: "/chemical/list", icon: <Science fontSize="large" />, },
        { title: "LocationList", path: "/location/list", hidden: !user.name, icon: <LocationOn fontSize="large" /> },
        { title: "Administration", path: "/admin", hidden: !hasRole([UserRoles.CSR]), icon: <AdminPanelSettings fontSize="large" /> },
        { title: "CreatePhrase", path: "/create_phrase", hidden: !hasRole([UserRoles.CSR]), icon: <Create fontSize="large" /> },
        { title: "QrPrinting", path: "/qr_printing", hidden: !hasRole([UserRoles.CSR]), icon: <QrCode2Sharp fontSize="large" /> },
        { title: "LabelPrinting", path: "/label_printing", hidden: !hasRole([UserRoles.CSR]), icon: <Notes fontSize="large" /> }
    ], [user]);

    return (
        <BrowserRouter>
            <LayoutProvider
                title={packageJson.name}
                appVersion={packageJson.version}
                primaryColor={green[400]}
                secondaryColor={purple[400]}
                leftDrawerItems={leftDrawerItems}>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/new_application' element={<NewApplicationPage />} />
                    <Route path='/create_chemical' element={<CreateChemicalPage />} />
                    <Route path='/create_location' element={<CreateLocationPage />} />
                    <Route path='/chemical/list' element={<ChemicalList />} />
                    <Route path='/location/list' element={<LocationList />} />
                    <Route path='/chemical/:id' element={<ChemicalDetailPage />} />
                    <Route path='/location/:id' element={<LocationDetailPage />} />
                    <Route path='/application/list/:type' element={<ApplicationListPage />} />
                    <Route path='/application/:id' element={<ApplicationDetailPage />} />
                    <Route path='/qr_landing/:id' element={<QrLandingPage />} />
                    <Route path='/admin' element={<CasAdmin />} />
                    <Route path='/create_phrase' element={<CreateSentence />} />
                    <Route path='/qr_printing' element={<QrPrinting />} />
                    <Route path='/label_printing' element={<LabelPrinting />} />
                </Routes>
            </LayoutProvider>
        </BrowserRouter>
    );
}