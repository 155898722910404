import React, { useContext } from 'react';
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams, Checkbox, CheckboxProps, FormControl, FormControlProps, Input, InputLabel, InputProps, Select, SelectChangeEvent, SelectProps, TextField, TextFieldProps, Theme } from '@mui/material';
import YesNoInput, { YesNoInputProps } from './YesNoInput';
import { CapitalizeFirst } from '../../utils/StringUtils';
import { ValidationRule } from '../../utils/UseFormControls';
import ExtendedAutocomplete, { ExtendedAutocompleteModel, ExtendedAutocompleteProps } from './extended_autocomplete/ExtendedAutocomplete';
import { LayoutContext } from 'wcz-layout';

type MyInputProps = InputProps | TextFieldProps | CheckboxProps | YesNoInputProps
    | SelectProps | ExtendedAutocompleteProps | AutocompleteProps<any, any, any, any>

interface MyFormControlProps<ModelType> {
    attributeName: keyof ModelType,
    modelState: [ModelType, React.Dispatch<React.SetStateAction<ModelType>>],
    inputTag: typeof Input | typeof TextField | typeof Checkbox | typeof YesNoInput | typeof Select | typeof ExtendedAutocomplete | typeof Autocomplete,
    inputProps?: MyInputProps,
    disabled: boolean,
    validationRules?: ValidationRule[],
    getHandleChange: (inputTag: any) =>
        ((e: any, value: any) => void) | ((e: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void),
    formControlProps?: Partial<FormControlProps>,
    label?: string
}

export default function MyFormControl<ModelType extends { [key: string]: any }>(props: MyFormControlProps<ModelType>) {
    const { t } = useContext(LayoutContext);
    const value = props.modelState[0][props.attributeName];
    const setValue = (value: any) => {
        props.modelState[1]((previousState: ModelType) => {
            return {
                ...previousState,
                [props.attributeName]: value
            };
        });
    };

    const commonProps = {
        value: value,
        name: props.attributeName,
        disabled: props.disabled,
        required: isRequired(props.validationRules),
        onChange: props.getHandleChange(props.inputTag)
    };

    let tagSpecificProps: Partial<MyInputProps> = {};

    switch (props.inputTag) {
        case Input:
            tagSpecificProps = {
                type: "text",
                inputProps: {
                    maxLength: getMaxLength(props.validationRules)
                }
            } as Partial<InputProps>;
            break;
        case TextField:
            tagSpecificProps = {
                inputProps: {
                    maxLength: getMaxLength(props.validationRules)
                },
                multiline: true,
                minRows: 1,
                maxRows: 10,
            } as Partial<TextFieldProps>;
            break;
        case Checkbox:
            tagSpecificProps = {
                onChange: (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    setValue(checked);
                }
            } as Partial<CheckboxProps>;
            break;
        case Select:
            tagSpecificProps = {
                variant: "standard",
                onChange: (e: SelectChangeEvent<unknown>) => {
                    setValue(e.target.value);
                }
            } as Partial<SelectProps>;
            break;
        case Autocomplete:
            tagSpecificProps = {
                renderInput: (params: AutocompleteRenderInputParams) => <TextField {...params} variant="standard" margin="normal" />,
                onChange: (_e: React.SyntheticEvent<Element, Event>, value: any) => {
                    setValue(value);
                }
            } as Partial<AutocompleteProps<any, any, any, any>>;
            break;
        case YesNoInput:
            tagSpecificProps = {
                setValue: setValue as (value: any) => void
            } as Partial<YesNoInputProps>;
            break;
        case ExtendedAutocomplete:
            tagSpecificProps = {
                setValue: (setValue as (newValue: ExtendedAutocompleteModel[]) => void)
            } as Partial<ExtendedAutocompleteProps>;
            break;
    }

    const inputElementProps = {
        ...commonProps,
        ...tagSpecificProps,
        ...props.inputProps,
    };

    const inputElement = React.createElement(props.inputTag as any, inputElementProps as any);

    return (
        <FormControl
            fullWidth
            disabled={props.disabled}
            margin="normal"
            required={isRequired(props.validationRules)}
            {...props.formControlProps}
        >
            <InputLabel
                hidden={props.inputTag === ExtendedAutocomplete}
                shrink
                sx={(theme: Theme) => ({
                    display: props.inputTag === ExtendedAutocomplete ? "none" : undefined,
                    backgroundColor: props.inputTag === TextField ? theme.palette.background.paper : "initial",
                    paddingLeft: "0.2em",
                    paddingRight: "0.2em"
                })}
            >
                {props.label ?? t(CapitalizeFirst(props.attributeName.toString()))}
            </InputLabel>
            {inputElement}
        </FormControl>
    );
}

function isRequired(rules?: ValidationRule[]): boolean {
    return !!rules && rules.includes("required");
}

function getMaxLength(rules?: ValidationRule[]): number | undefined {
    return rules?.find(r => r[0] === "maxLength")?.[1] as number | undefined;
}