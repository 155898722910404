import { useContext, useMemo, useState } from 'react';
import { FilePresent } from '@mui/icons-material';
import { IconButton, IconProps, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import packageJson from '../../../package.json';
import { fileApiUrl } from '../../utils/BaseUrl';
import { fetchFileGet, fetchGet, LayoutContext } from 'wcz-layout';
import { useQuery } from '@tanstack/react-query';
import MetaInfo from '../../models/file/MetaInfo';
import { MediaType } from '../../models/file/MediaType';
import { saveAs } from 'file-saver';

interface FileLinkProps {
    subId: string,
    big?: boolean
}

export default function FileLink(props: FileLinkProps) {
    const { t } = useContext(LayoutContext);
    const [index, setIndex] = useState<number>(0);

    const { data: metaData } = useQuery<MetaInfo[], Response>(["meta", props.subId],
        ({ signal }) => fetchGet(`${fileApiUrl}/v1/meta?appName=${packageJson.name}&subId=${props.subId}`,
            signal),
        {
            refetchOnWindowFocus: false,
        });

    const { refetch: download } = useQuery(["download", props.subId],
        ({ signal }) => fetchFileGet(`${fileApiUrl}/v1/download?appName=${packageJson.name}&id=${metaData![index].id}`,
            signal), {
        enabled: false,
        onSuccess: (data) => saveAs(data, metaData![index].fileName)
    });

    const { refetch: openPdf } = useQuery(["openPDF", props.subId],
        ({ signal }) => fetchFileGet(`${fileApiUrl}/v1?appName=${packageJson.name}&id=${metaData![index].id}`,
            signal), {
        enabled: false,
        onSuccess: (data: Blob) => window.open(URL.createObjectURL(data))
    });

    const onButtonClick = () => {
        if (!metaData)
            return;
        switch (metaData[index].mediaType) {
            case MediaType.application: {
                if (metaData[index].fileExtension === "pdf")
                    return openPdf();

                return download();
            }
            default: return download();
        }
    };

    const iconProps: IconProps<any> = useMemo(() => {
        return props.big ? {
            sx: {
                fontSize: "60px"
            },
            color: "warning"
        } : {} as IconProps;
    }, [props.big]);

    if (!metaData)
        return <>Loading...</>;

    if (metaData?.length === 0)
        return <Typography sx={{ ml: 2 }}>{t("NoBlFound")}</Typography>;

    const handleSelectChange = (e: SelectChangeEvent<number>) => {
        setIndex(e.target.value as number);
    };

    return (
        <>
            <Select sx={{ ml: 1, maxWidth: 350 }} value={index} onChange={handleSelectChange}>
                {metaData.map(file =>
                    <MenuItem value={metaData.indexOf(file)}>{file.fileName}</MenuItem>
                )}
            </Select>
            <IconButton onClick={onButtonClick} sx={props.big ? {
                border: "#ed6c02 solid 2px",
                margin: "0.5em"
            } : {}}>
                <FilePresent {...iconProps} />
            </IconButton>
        </>
    );

}