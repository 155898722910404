import { Add, Delete, Info } from "@mui/icons-material";
import { Button, ToolbarProps } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridCellParams, GridColDef, GridRowId, GridRowModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { useCallback, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { fetchDelete, fetchGet, fetchPut, hasRole, LayoutContext } from "wcz-layout";
import { GridToolbarProps, TableContainer } from "wcz-x-data-grid";
import { ExtendedAutocompleteModel } from "../components/my_form/extended_autocomplete/ExtendedAutocomplete";
import ChemicalDto from "../dtos/ChemicalDto";
import { chemicalStateOptions } from "../types/chemicalState/ChemicalStateOptions";
import { chemicalUrls } from "../utils/BaseUrl";
import { getColumnDef } from "../utils/RenderUtils";
import { UserRoles } from "../utils/UserRoles";

export default function ChemicalList() {
    const { changeTitle, t, snackbar } = useContext(LayoutContext);
    const navigate = useNavigate();

    useEffect(() => {
        changeTitle(t("ApprovedChemicalsInWcz"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { data = [], refetch } = useQuery<ChemicalDto[], Response>(["approvedChemicalList"], ({ signal }) => fetchGet(chemicalUrls.list, signal));

    const { mutate: deleteChemical } = useMutation((id: GridRowId) =>
        fetchDelete(chemicalUrls.delete + id),
        {
            onSuccess: () => {
                snackbar({ message: t("Chemical") + " " + t("deleted"), severity: 'success' });
                refetch();
            }
        }
    );

    const updateChemical = useMutation((chemical: ChemicalDto) => fetchPut(`${chemicalUrls.update}/${chemical.id}`, chemical));

    const processRowUpdate = async (row: GridRowModel<ChemicalDto>): Promise<ChemicalDto> => {
        const toSentObject = row;
        delete toSentObject.svhcSubstance;
        delete toSentObject.isActive;
        updateChemical.mutateAsync(toSentObject);
        return row;
    };

    const getColumnDefCsrEditable = (fieldName: keyof ChemicalDto, type?: string, options: Partial<GridColDef> = {} as GridColDef): GridColDef => {
        return getColumnDef(fieldName, type, {
            ...options,
            editable: hasRole([UserRoles.CSR]) && (options?.editable === undefined || options.editable)
        });
    };

    const doubleClick = useCallback((params: GridCellParams) => {
        navigate(`/chemical/${params.id}`);
    }, [navigate]);

    const handleAddNewChemical = useCallback(() => {
        navigate("/create_chemical");
    }, [navigate]);

    const showAddChemical = () => {
        if (hasRole([UserRoles.CSR])) {
            return true;
        }
        return false;
    };

    const customToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
                {showAddChemical() && <Button size="small" type="button" color="primary" onClick={handleAddNewChemical} startIcon={<Add />}>{t("AddNewChemical")}</Button>}
            </GridToolbarContainer>
        );
    };

    const columns: GridColDef[] = [
        {
            field: 'actions', type: 'actions', width: 80, hideable: false,
            getActions: (params: any) => [
                <GridActionsCellItem
                    key={params.id}
                    icon={<Info />}
                    label={t("Detail")}
                    onClick={() => navigate(`/chemical/${params.id}`)}
                />,
                <GridActionsCellItem
                    key="delete"
                    sx={{ display: !hasRole([UserRoles.CSR]) ? "none" : undefined }}
                    icon={<Delete />}
                    label={t("Delete")}
                    onClick={() => deleteChemical(params.id)}
                />,
            ]
        },
        getColumnDefCsrEditable("name"),
        getColumnDefCsrEditable("supplier"),
        getColumnDefCsrEditable("blNumber"),
        getColumnDefCsrEditable("blPublicationDate"),
        getColumnDefCsrEditable("state", undefined, {
            type: "singleSelect",
            valueOptions: chemicalStateOptions
        }),
        getColumnDefCsrEditable("density", "number"),
        getColumnDefCsrEditable("hazardClass"),
        getColumnDefCsrEditable("flashpoint", "number"),
        getColumnDefCsrEditable("cas", undefined, {
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.join("; ")
        }),
        getColumnDefCsrEditable("hsentences", undefined, {
            editable: false,
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.map(v => v.shortText).join("; ")
        }),
        getColumnDefCsrEditable("psentences", undefined, {
            editable: false,
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.map(v => v.shortText).join("; ")
        }),
        getColumnDefCsrEditable("ghsSymbol", undefined, {
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.join("; ")
        }),
        getColumnDefCsrEditable("svhcSubstance", "boolean", {
            editable: false
        }),
        getColumnDefCsrEditable("isActive", "boolean", {
            editable: false
        }),
        getColumnDefCsrEditable("dangerousSubstanceCategories", undefined, {
            valueFormatter: ({ value }: GridValueFormatterParams<ExtendedAutocompleteModel[]>) => value.join("; ")
        }),
        getColumnDefCsrEditable("dangerousByWaterLaw", "boolean"),
        getColumnDefCsrEditable("extraDangerousByWaterLaw", "boolean"),
        getColumnDefCsrEditable("restrictedForPregnants", "boolean"),
        getColumnDefCsrEditable("restrictedForYouthful", "boolean"),
        getColumnDefCsrEditable("khsRulesRecommended", "boolean"),
        getColumnDefCsrEditable("voc", "number"),
        getColumnDefCsrEditable("firstAidGeneral"),
        getColumnDefCsrEditable("firstAidInhalation"),
        getColumnDefCsrEditable("firstAidSkinContact"),
        getColumnDefCsrEditable("firstAidEyeContact"),
        getColumnDefCsrEditable("firstAidIngestion"),
        getColumnDefCsrEditable("fireExtinguisherSuitable"),
        getColumnDefCsrEditable("fireExtinguisherNonSuitable"),
        getColumnDefCsrEditable("protectionAirways"),
        getColumnDefCsrEditable("protectionHands"),
        getColumnDefCsrEditable("protectionEyes"),
        getColumnDefCsrEditable("protectionSkinAndBody"),
        getColumnDefCsrEditable("wasteTypeCode"),
        getColumnDefCsrEditable("wasteType"),
        getColumnDefCsrEditable("wasteSubgroup"),
        getColumnDefCsrEditable("wasteGroup"),
        getColumnDefCsrEditable("manipulation"),
        getColumnDefCsrEditable("storage")
    ];

    return (
        <TableContainer>
            <DataGridPro rows={data} columns={columns} components={{ Toolbar: customToolbar }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "name", sort: "asc" }]
                    }
                }}
                slotProps={{
                    toolbar: {
                        hideAddRecord: true
                    } as GridToolbarProps
                }}
                editMode="row"
                processRowUpdate={processRowUpdate}
                onCellDoubleClick={doubleClick}
            />
        </TableContainer>
    );
}